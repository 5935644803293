import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 text-2xl" }
const _hoisted_2 = { class: "py-2 text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('global.how_to_cite')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.precitation ? _ctx.precitation + _ctx.mainCitation : _ctx.mainCitation), 1)
  ], 64))
}