import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "overflow-x-auto border showLinks p-2 my-4" }
const _hoisted_2 = { class: "table-auto" }
const _hoisted_3 = { class: "align-top" }
const _hoisted_4 = {
  key: 0,
  class: "whitespace-normal align-top pl-2"
}
const _hoisted_5 = {
  key: 1,
  class: "whitespace-normal pl-2"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableFields, (f) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: f[0],
            class: ""
          }, [
            _createElementVNode("td", _hoisted_3, _toDisplayString(`${f[0]}:`), 1),
            (f.length == 2)
              ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(f[1]), 1))
              : (f.length === 3)
                ? (_openBlock(), _createElementBlock("td", _hoisted_5, [
                    _createElementVNode("a", {
                      href: f[2],
                      target: "_blank"
                    }, _toDisplayString(f[1]), 9, _hoisted_6)
                  ]))
                : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("p", _hoisted_7, _toDisplayString(`${_ctx.$t('manuscripts.editors')}: ${
          'citation' in _ctx.manuscript ? _ctx.manuscript.citation : _ctx.manuscript.editors
        }`), 1)
  ]))
}